import { createTheme } from "@material-ui/core/styles";

import { primaryColor, secondaryColor } from "./jss/nextjs-material-kit.js";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: secondaryColor,
    },
  },

  typography: {
    fontFamily: ['"Fira Sans"', '"Helvetica"', '"sans-serif"'].join(","),

    subtitle1: {
      fontSize: "14px !important",
      fontWeight: 500,
      letterSpacing: "normal",
      marginTop: "0.5em",
      marginBottom: "0.5em",
    },

    subtitle2: {
      fontWeight: 500,
      marginTop: "1em",
      marginBottom: "0.5em",
      fontSize: "17px",
      //textTransform: 'uppercase',
    },

    h2: {
      fontSize: "26px !important",
      fontWeight: 700,
      marginBottom: "0.75em",
      marginTop: "0.25em",
    },

    h3: {
      fontSize: "22px !important",
      fontWeight: 700,
      marginBottom: "0.75em",
      marginTop: "1.5em",
    },

    h4: {
      fontSize: "18px !important",
      fontWeight: 600,
      marginBottom: "0.5em",
      marginTop: "1em",
    },

    h6: {
      fontSize: "18px !important",
      fontWeight: 600,
    },
  },
});
